import React from 'react';
import { connect } from "react-redux";
import MetaTags from 'react-meta-tags';

import './InnerBlog.css';
import * as blogActions from "../../../actions/blogActions";


const mapStateToProps = store => {
    return {
        article: store.blogArticle.article,
        isLoading: store.blogArticle.fetching,
        hasError: store.blogArticle.error !== undefined,
        errorMessage: store.blogArticle.error,
    }
};

class InnerBlog extends React.Component {
    constructor() {
        super();
        this.createMarkup = this.createMarkup.bind(this);
        this.showArticle = this.showArticle.bind(this);
    }

    componentWillMount() {
	    this.props.dispatch(blogActions.fetchBlog(this.props.match.params.slug));
	}

	createMarkup() {
        return {__html: this.props.article.html};
    }

    showArticle() {
        const {article} = this.props,
              publishedAt = new Date(article.published_at).toDateString();
        return (
            <div>
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>{article.title}</h1>
                        <p>{article.summary}</p>
                    </section>
                </div>
				<div className="row-block">
                    <div className="container">
                        <div className="row blog-text">
                            <section className="col-md-12" dangerouslySetInnerHTML={this.createMarkup(article.html)} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { article } = this.props;
        return (
            <div className="innerblog-page-content">
                <MetaTags>
                    <title>{"Chiliseed | " + article.title}</title>
                    <meta name="description" content="" />
                    <meta property="og:title" content={"Chiliseed | " + article.title} />
                </MetaTags>
                {this.props.isLoading ? <div className="blog-loading"><i className="fas fa-circle-notch fa-spin"/></div> : this.showArticle() }
            </div>
        );
    }
}

const VisibleBlogPost = connect(mapStateToProps)(InnerBlog);
export default VisibleBlogPost;
