import React from "react";
import {connect} from "react-redux";
import MetaTags from 'react-meta-tags'

import './Blog.css';
import * as blogActions from "../../actions/blogActions";
import BlogArticle from "../../components/BlogArticle";
import DevOpsDecisions from './Blogs/DevOpsDecisions';
import TrueCostDevOps from './Blogs/TrueCostDevOps';

import blogImg1 from './images/blog1.jpg';
import blogImg2 from './images/blog2.jpg';
import blogImg3 from './images/blog3.jpg';
import blogImg4 from './images/blog4.jpg';

const post1 = {
    slug: 'insights/devops-decisions',
    image: blogImg1,
    title: 'DevOps decisions',
    summary: 'DevOps is a big thing today. Everybody needs it, everybody knows they need it, but many don’t know how to do it properly or just don’t have the budget for it.',
};

const post2 = {
    slug: 'insights/true-cost-devops',
    image: blogImg2,
    title: 'The true cost of DevOps infrastructure',
    summary: 'When we plan the cost of our hi-tech venture we tend to assess every component in terms of its price. Let’s delve into the hidden costs pertaining to infrastructure decisions.',
};

const post3 = {
    slug: 'insights/time-to-market',
    image: blogImg3,
    title: 'Time to market and ready-to-use code',
    summary: 'Time to market means a great deal to hi-tech companies and their projects. In many cases, it’s the difference between success and failure.',
};

const post4 = {
    slug: 'insights/critical-paths-startup',
    image: blogImg4,
    title: 'The critical paths of a start-up',
    summary: 'Every start-up founder can tell you how challenging the journey is from an idea to a successful start-up, but it all comes down to a few critical paths that make it such a challenge.',
};

const mapStateToProps = store => {
    return {
        articles: store.blog.blog,
        isLoading: store.blog.fetching,
        hasError: store.blog.error !== undefined,
        errorMessage: store.blog.error,
    }
};


class Blog extends React.Component {
    constructor() {
        super();
        this.state = {winHeight: 0};
    }

    /*componentWillMount() {
        if (this.props.articles.length === 0) {
            this.props.dispatch(blogActions.fetchBlogs());
        }
    }*/

    componentDidMount() {
        let winHeight = window.innerHeight - (this.refs.landingStrip.clientHeight + 96);
        this.setState({winHeight});
    }

    render() {
        let listHolder = [];
        const posts = [
            post4,
            post3,
            post2,
            post1,
        ];
        /*if (this.props.articles.length > 0) {
            listHolder = this.props.articles.map((post) => {
                return <BlogArticle post={post} key={post.slug}/>
            });
        }*/
        if (posts.length > 0) {
            listHolder = posts.map((post) => {
                return <BlogArticle post={post} key={post.slug}/>
            });
        }
        return (
            <div className="blog-page-content">
                <MetaTags>
                    <title>Chiliseed | Insights</title>
                    <meta name="description" content="We are constantly researching advancements in the field of infrastructure. Get an idea of the challenges we help to deal with and catch up on our latest news." />
                    <meta property="og:title" content="Chiliseed | Insights" />
                </MetaTags>
                <div className="landing-strip row-block" ref="landingStrip">
                    <section className="container">
                        <h1>Our latest insights</h1>
                        <p>
                            We are constantly researching advancements in the field of infrastructure.<br/>
                            Get an idea of the challenges we help to deal with and catch up on our latest news.
                        </p>
                    </section>
                </div>
                <div className="blog-archives row-block">
                    <div style={{minHeight: this.state.winHeight + 'px'}} className="container">
                        {listHolder}
                        {this.props.isLoading ?
                            <div className="blog-loading"><i className="fas fa-circle-notch fa-spin"/></div> : null }
                    </div>
                </div>
            </div>
        );
    }
}


// Connect our Blog component to Redux store management
/*const VisibleBlogPosts = connect(mapStateToProps)(Blog);
export default VisibleBlogPosts;*/

export default Blog;