import React from "react";
import MetaTags from 'react-meta-tags'

import './Contact.css';
import ContactForm from "../../components/ContactForm/ContactForm";
import contactImg from './contact.png';

class Contact extends React.Component {
	render() {
        return (
            <div className="contact-page-content">
                <MetaTags>
                    <title>Chiliseed | Get in Touch</title>
                    <meta name="description" content="Please leave your details and  we will get back to you as soon as possible within the next 48 hours.<br/>We look forward to hearing from you." />
                    <meta property="og:title" content="Chiliseed | Get in Touch" />
                </MetaTags>
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>Let’s hear from you</h1>
                        <p>
                            Please leave your details and  we will get back to you as soon as possible within the next 48 hours.<br/>We look forward to hearing from you.
                        </p>
                    </section>
                </div>
                <div className="row-block">
                    <div className="container">
                        <div className="row">
							<section className="col-md-12">
							</section>
                            <section className="col-md-8">
								<h2>Get in touch</h2>
								<ContactForm origin="GetInTouchForm" />
                            </section>
							<section className="col-md-4">
								<img className="img-fluid" alt="contact"
                                     src={contactImg}/>
							</section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;
