import React from "react";
import { Link } from "react-router-dom";

import './Footer.css';


class Footer extends React.Component {
    render() {
        return (
            <footer className="row-block">
                <div className="container">
                    <div className="row">
                        <section className="col-md-6">
                            <h4>Let’s help you build something fabulous</h4>
                            <Link to="/contact" className="btn btn-primary">GET IN TOUCH</Link>
                        </section>
                        <section className="col-md-6">
                            <div className="social">
                                <a className="linkedin" href="https://www.linkedin.com/company/chiliseed/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-linkedin-in" />
                                </a>
                                <a className="facebook" href="#" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook-f" />
                                </a>
								<a className="twitter" href="#" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-twitter" />
                                </a>
                            </div>
                            <div className="footer-links">
                                <Link to="/terms-of-use">Terms of Use</Link> | <Link to="/privacy-policy">Privacy Policy</Link>
                            </div>
                        </section>
                        <section className="col-md-12">
                            <p className="copyright text-center">© Copyright <Link to="/">chiliseed.com</Link> | All Rights Reserved </p>
                        </section>
                    </div>
                </div>
            </footer>

        );
    }
}

export default Footer;
