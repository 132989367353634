import React from "react";
import MetaTags from 'react-meta-tags'

import './GetStarted.css';

class GetStarted extends React.Component {
    render() {
        return (
            <div className="contact-page-content">
                <MetaTags>
                    <title>Chiliseed | Get Started</title>
                    <meta name="description" content="Your time and money is precious. Start your project off with zero costs and expand as required. Get started with a ready-to-use environment that guarantees speed and value." />
                    <meta property="og:title" content="Chiliseed | Get Started" />
                </MetaTags>
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>Take the shortcut</h1>
                        <p>
                            Your time and money is precious. Start your project off with zero costs using our open source tool.<br/>
                            Get started with ready-to-use environments that guarantee speed and value.
                        </p>
                    </section>
                </div>
                <div className="row-block features">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-12">
                                <h2>Chiliseed Hub</h2>
                                <p>Chiliseed Hub is an open source service that manages pre-defined, production ready architectures for deploying containerized applications to AWS. <br/>
                                    Each piece of infrastructure is managed by a corresponding executor. <br/>
                                    Ultimately, the Hub combines all the pieces to provide a cohesive architecture, in accordance with best practices.</p>
                                <p>Be sure to check out our demo below to see just how simple it is to get started.</p>
                            </section>
                        </div>
                        <div className="row">
                            <section className="col-md-4">
                                <div className="card mb-5 mb-lg-0">
                                    <div className="card-body">
                                        <h5 className="card-title text-muted text-uppercase text-center">Chiliseed Hub</h5>
                                        <h6 className="card-price text-center"><span className="period">Features</span></h6>
                                        <hr/>
                                        <ul className="fa-ul">
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Unlimited environments</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Unlimited databases (PostgreSQL)</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>ECS + ECR</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>CLI deployment tool</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Static files buckets on S3</li>
                                        </ul>
                                        <a href="https://github.com/chiliseed/hub" target="_blank" className="btn btn-block btn-primary text-uppercase"><i className="fab fa-github"/>GitHub Repository</a>
                                    </div>
                                </div>
                            </section>
                            <section className="col-md-8">
                                <div className="video-wrapper" id="hub-demo">
                                    <iframe src="https://drive.google.com/file/d/1xV4dxoi1pgOZcUAUwUt3MCKcPzX-U3Ke/preview" width="1920" height="1080" />
                                </div>
                            </section>
                        </div>
                        <div className="row">
                            <section className="col-md-12 clearfix">
                                <h4>Require more features? Want a hosted version? Don't hesitate to <a href="/contact">drop us a line</a>.</h4>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GetStarted;
