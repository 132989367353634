import React from 'react';
import MetaTags from 'react-meta-tags'

import ContactForm from "../../components/ContactForm/ContactForm";
import contactImg from './404.png';

class FourOFour extends React.Component {
    render() {
        return (
            <div>
                <MetaTags>
                    <title>Chiliseed | Oops... You broke the internet!</title>
                    <meta name="description" content="Looks like you can really use our expert help. Feel free to drop us a line and we will get back to you shortly." />
                    <meta property="og:title" content="Chiliseed | Oops... You broke the internet!" />
                </MetaTags>
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>Oops... You broke the internet!</h1>
                        <p>Looks like you can really use our expert help. Feel free to drop us a line and we will get back to you shortly.</p>
                    </section>
                </div>
				<div className="row-block">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-8">
								<h2>Contact for help</h2>
								<ContactForm origin="FourOFourForm" />
                            </section>
							<section className="col-md-4">
								<img className="img-fluid" alt="contact" src={contactImg}/>
							</section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FourOFour;
