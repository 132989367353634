import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from 'react-meta-tags';

import '../InnerBlog/InnerBlog.css';
import blogImg from '../images/blog4.jpg';
import * as blogActions from "../../../actions/blogActions";


const mapStateToProps = store => {
    return {
        article: store.blogArticle.article,
        isLoading: store.blogArticle.fetching,
        hasError: store.blogArticle.error !== undefined,
        errorMessage: store.blogArticle.error,
    }
};

class CriticalPathsStartup extends React.Component {
    /*constructor() {
     super();
     this.createMarkup = this.createMarkup.bind(this);
     this.showArticle = this.showArticle.bind(this);
     }

     componentWillMount() {
     this.props.dispatch(blogActions.fetchBlog(this.props.match.params.slug));
     }

     createMarkup() {
     return {__html: this.props.article.html};
     }*/

    showArticle() {
        //const {article} = this.props;
        const publishedAt = new Date(2019, 11, 25, 0, 0, 0, 0).toDateString();
        return (
            <div>
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>The Critical Paths of a Start-up</h1>
                        <p>Every start-up founder can tell you how challenging the journey is from an idea to a successful start-up, but it all comes down to a few critical paths that make it such a challenge.</p>
                    </section>
                </div>
                <div className="row-block">
                    <div className="container">
                        <div className="row blog-text justify-content-md-center">
                            <section className="col-md-8">
                                <div className="row">
                                    <section className="col-md-6">
                                        <Link to="/insights"><small><i className="fas fa-arrow-left"/> Back to insights</small></Link>
                                    </section>
                                    <section className="col-md-6 text-md-right">
                                        <p><small>Published on {publishedAt}</small></p>
                                    </section>
                                </div>
                                <div className="blog-post-img">
                                    <img src={blogImg} className="img-fluid" alt="devops decisions" />
                                </div>
                                <p>Many articles, podcasts and blogs were written and recorded on the subject of start-up challenges.</p>
                                <p>Here are some of the critical paths that most start-ups have in common:</p>
                                <ul>
                                    <li>Product: time to MVP (minimum viable product) and to scale</li>
                                    <li>Market fit</li>
                                    <li>Runway (time left based on cash level & burn rate)</li>
                                    <li>Competition & market</li>
                                </ul>
                                <p>Each path is critical and not reaching the goals for any given path will probably impact all other paths. On the other hand, if you have great market fit and time to market, for example, it might buy you precious time for the other paths.</p>
                                <p>What can be done in order to better manage your critical paths and the inherent risk of developing a product that has never been developed or sold before?</p>
                                <p>The first thing is to balance the speed and the cash. With a higher burn rate you can develop faster and get to the market quicker. Practically, cash buys time and shortens each critical path.  The trade-off is that you may run out of cash before your next round of investment, which might kill your start-up.</p>
                                <p>Focusing on what matters most is also crucial. In the early stages, the R&D and ability to get to the MVP is a live or die milestone. What is important is to focus on what is critical content of the MVP and how to get it developed fast enough before you run out of cash. In that path there are numerous decisions that can have a dramatic impact on your time to market but also on the future of your start-up post MVP.</p>
                                <p>There are more things that can be done that are not necessarily more expensive or obvious, like focusing and prioritizing. During the development sprint for the MVP and under that pressure, you can choose to how much technical debt you want to accumulate. It means that all the non-urgent and not critical tasks can wait. When it comes to infrastructure, there is a dilemma, as the infrastructure for the MVP is certainly not going to be the same at scale. Therefore, the right decision might be to invest the minimum in infrastructure for the MVP and later, when there is more time and money, to scale up and invest considerably more.</p>
                                <p>At Chiliseed, we believe there is no need to accumulate technical debt related to infrastructure, and the infrastructure should not delay the MVP. Our product was developed to resolve that difficult, as you can have both a local development environment and a ready to use production environment in less than 48 hours without accumulating technical debt and with minimum R&D resources. Budget wise, you can <Link to="/get-started">get started with Chiliseed Hub</Link>, our open source tool, which is sufficient for an MVP.</p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        //const { article } = this.props;
        return (
            <div className="innerblog-page-content">
                <MetaTags>
                    <title>Chiliseed | The critical paths of a start-up</title>
                    <meta name="description" content="Every start-up founder can tell you how challenging the journey is from an idea to a successful start-up, but it all comes down to a few critical paths that make it such a challenge." />
                    <meta property="og:title" content="Chiliseed | The Critical Paths of a Start-up" />
                </MetaTags>
                {this.props.isLoading ? <div className="blog-loading"><i className="fas fa-circle-notch fa-spin"/></div> : this.showArticle() }
            </div>
        );
    }
}

/*const VisibleBlogPost = connect(mapStateToProps)(DevOpsDecisions);
 export default VisibleBlogPost;*/

export default CriticalPathsStartup;
