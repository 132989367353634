import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";

import './App.css';
import Header from "./Header/Header";
import Footer from './Footer/Footer';
import Home from "../../pages/Home/Home";
import Features from "../../pages/Features/Features";
import Pricing from "../../pages/Pricing/Pricing";
import GetStarted from "../../pages/GetStarted/GetStarted";
import WhyChiliseed from '../../pages/WhyChiliseed/WhyChiliseed';
import AboutUs from '../../pages/AboutUs/AboutUs';
import Blog from "../../pages/Blog/Blog";
import BlogInner from "../../pages/Blog/InnerBlog/InnerBlog";
import Contact from "../../pages/Contact/Contact";
import FourOFour from "../../pages/Error/FourOFour";
import TermsOfUse from "../../pages/Legal/TermsOfUse";
import PrivacyPolicy from "../../pages/Legal/PrivacyPolicy";
import EULA from "../../pages/Legal/EULA";
import ScrollToTop from "../ScrollToTop";
import Portfolio from "../../pages/Portfolio/Portfolio";
import DevOpsDecisions from "../../pages/Blog/Blogs/DevOpsDecisions";
import TrueCostDevOps from "../../pages/Blog/Blogs/TrueCostDevOps";
import TimeToMarket from "../../pages/Blog/Blogs/TimeToMarket";
import CriticalPathsStartup from "../../pages/Blog/Blogs/CriticalPathsStartup";

const supportHistory = 'pushState' in window.history;


class App extends React.Component {
    render() {
        const msg = `
		                                                                               
                                                                               
                                               ...                             
                                     (##################(                      
                               .############(*,,*######## *&.                  
                            ########( .#################  #&&&                 
                         ####### .#####################( %&&&&&                
                      /#####( /########################## /&&&&&&&,            
                    ###### ############################# ,&&&&&   (&*          
                  (####, ##############################  &&&&&      &          
                ,####, ################################# (&(                   
               ##### ##################################                        
              ####.#######################/.                                   
             #### ##################,                                          
            #### ################                                              
           ####,##############*                                                
           ####/############,                                                  
          (################                                                    
          ################                                                     
          ###############                                                      
          ##############                                                       
          ##############                                                       
          ##############                                                       
          (#############                                                       
           #############                                                       
           #############                                                       
            #############                                                      
             #############                                                     
              #############                                                    
               #############                                                   
                 #############                                                 
                  ,############/                                               
                    ,#############.                                            
                       ##############*                             /#          
                          ################*                   *####            
                             ##################################(               
                                  /#######################(                    
                                          .,//(//,.                            
                                                                               


`;
        console.log(msg);
        return (
            <div className="App">
                <BrowserRouter forceRefresh={!supportHistory}>
                    <div className="mainPageContainer">
                        <Header/>
                        <div className="page-content">
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route path="/features" component={Features}/>
                                {/*<Route path="/pricing" component={Pricing}/>*/}
                                <Route path="/get-started" component={GetStarted}/>
                                <Route path="/why-chiliseed" component={WhyChiliseed}/>
                                <Route path="/about" component={AboutUs}/>
                                <Route exact path="/blog/:slug" component={BlogInner}/>
                                <Route exact path="/insights" component={Blog}/>
                                <Route exact path="/insights/devops-decisions" component={DevOpsDecisions}/>
                                <Route exact path="/insights/true-cost-devops" component={TrueCostDevOps}/>
                                <Route exact path="/insights/time-to-market" component={TimeToMarket}/>
                                <Route exact path="/insights/critical-paths-startup" component={CriticalPathsStartup}/>
                                <Route path="/portfolio" component={Portfolio}/>
                                <Route path="/contact" component={Contact}/>
                                <Route path="/terms-of-use" component={TermsOfUse} />
                                <Route path="/privacy-policy" component={PrivacyPolicy} />
                                <Route path="/eula" component={EULA} />
                                <Route component={FourOFour} />
                            </Switch>
                        </div>
                        <Footer/>
                        <ScrollToTop/>
                    </div>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
