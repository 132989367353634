import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from 'react-meta-tags';

import '../InnerBlog/InnerBlog.css';
import blogImg from '../images/blog2.jpg';
import * as blogActions from "../../../actions/blogActions";


const mapStateToProps = store => {
    return {
        article: store.blogArticle.article,
        isLoading: store.blogArticle.fetching,
        hasError: store.blogArticle.error !== undefined,
        errorMessage: store.blogArticle.error,
    }
};

class TrueCostDevOps extends React.Component {
    /*constructor() {
     super();
     this.createMarkup = this.createMarkup.bind(this);
     this.showArticle = this.showArticle.bind(this);
     }

     componentWillMount() {
     this.props.dispatch(blogActions.fetchBlog(this.props.match.params.slug));
     }

     createMarkup() {
     return {__html: this.props.article.html};
     }*/

    showArticle() {
        //const {article} = this.props;
        const publishedAt = new Date(2019, 11, 4, 0, 0, 0, 0).toDateString();
        return (
            <div>
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>The True Cost of DevOps Infrastructure</h1>
                        <p>When we plan the cost of our hi-tech venture we tend to assess every component in terms of its price. <br/>
                            Let’s delve into the hidden costs pertaining to infrastructure decisions.</p>
                    </section>
                </div>
                <div className="row-block">
                    <div className="container">
                        <div className="row blog-text justify-content-md-center">
                            <section className="col-md-8">
                                <div className="row">
                                    <section className="col-md-6">
                                        <Link to="/insights"><small><i className="fas fa-arrow-left"/> Back to insights</small></Link>
                                    </section>
                                    <section className="col-md-6 text-md-right">
                                        <p><small>Published on {publishedAt}</small></p>
                                    </section>
                                </div>
                                <div className="blog-post-img">
                                    <img src={blogImg} className="img-fluid" alt="true cost of devops infrastructure" />
                                </div>
                                <p>The thing about hidden costs is that they eventually hit us hard in the future, as we accumulate "technical debt" which is hard to quantify. You know it's there, you can’t forget it's there and you assume you will deal with it down the road when you have the time and budget for it. Also, it’s hard to estimate items like cloud services, both for development and for production, along with other DevOps and backend tools you will inevitably use.</p>
                                <p>In early stages, the preference is usually not to use in-house DevOps and delay whatever is not essential. The alternative is open source code, PaaS products or in-house development. Open source code or PaaS products will require additional integration of some sort. In-house development will cost you the precious time of your developer. Also, usually the backend developer to whom you will assign the task is not necessarily an expert in DevOps and therefore it will take him significantly longer to configure the nuts and bolts correctly.</p>
                                <p>Additionally, for both open source and PaaS there are extra costs. Open source solutions usually come without any support or future upgrades. It means that your developer will be responsible for the integration as well as future support and maintenance, and that's before we mention security issues. PaaS hidden costs might arise from the lack of flexibility, difficult integration and hard lock-in, meaning it will be very hard to switch later to another solution. Lock-in might also be very expensive and it is impossible to know the true cost right from the outset.</p>
                                <p>Therefore, as you proceed through the stages of development, you will find out that you need more and more tools to maintain your operation both in local environment and production. Bear in mind that every tool chosen will require the attention of your developer. Maybe it's not a lot for every tool but all the tools combined can accumulate to a few thousand dollars a month and maybe more. We have already come across companies paying above $10K a month for the R&D manpower required for configuring cloud services and the numbers can go even higher if costs aren’t managed carefully.</p>
                                <p>Another thing to take into account, especially if your product is serving customers, is that it might end up being very expensive once you scale up. This expense will directly impact your cost of goods sold (COGS) and affect your gross margin, which is a major KPI for every company.</p>
                                <p>To summarize, there are many hidden costs that you will probably discover at later stages, at which point it might be too late. At Chiliseed, we believe in transparency from the outset and throughout all stages of the development of your product.</p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        //const { article } = this.props;
        return (
            <div className="innerblog-page-content">
                <MetaTags>
                    <title>Chiliseed | The true cost of DevOps infrastructure</title>
                    <meta name="description" content="When we plan the cost of our hi-tech venture we tend to assess every component in terms of its price. Let’s delve into the hidden costs pertaining to infrastructure decisions." />
                    <meta property="og:title" content="Chiliseed | The True Cost of DevOps Infrastructure" />
                </MetaTags>
                {this.props.isLoading ? <div className="blog-loading"><i className="fas fa-circle-notch fa-spin"/></div> : this.showArticle() }
            </div>
        );
    }
}

/*const VisibleBlogPost = connect(mapStateToProps)(DevOpsDecisions);
 export default VisibleBlogPost;*/

export default TrueCostDevOps;
