import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import './Header.css';


class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isTop: true,
        };
        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', () => {
            const isTop = window.scrollY < 100;
            if (isTop !== this.state.isTop) {
                this.onScroll(isTop);
            }
        });
    }

    onScroll(isTop) {
        this.setState({ isTop });
    }

    render() {
        return (
            <header>
                <Navbar fixed="top" variant="light" bg="light" expand="md"
                        className={this.state.isTop ? 'light' : 'dark'}>
                    <div className="container">
                        <Navbar.Brand>
                            <a href="/">
                                <img alt="chiliseed" className="logo-main"
                                     src={process.env.PUBLIC_URL + '/images/logo.png'}/>
                            </a>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse>
                            <Nav activeKey={window.location.pathname} className="ml-auto">
                                <Nav.Link href="/features">Features</Nav.Link>
                                {/*<Nav.Link href="/pricing">Pricing</Nav.Link>*/}
                                <Nav.Link href="/why-chiliseed">Why Chiliseed</Nav.Link>
                                <Nav.Link href="/about">About us</Nav.Link>
                                <Nav.Link href="/insights">Insights</Nav.Link>
                                <Nav.Link href="/contact">Contact</Nav.Link>
                                <Nav.Link href="/get-started" className="nav-btn">Get started</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>

                    </div>
                </Navbar>
            </header>
        );
    }
}


export default Header;
