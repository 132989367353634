import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from 'react-meta-tags';

import '../InnerBlog/InnerBlog.css';
import blogImg from '../images/blog1.jpg';
import * as blogActions from "../../../actions/blogActions";


const mapStateToProps = store => {
    return {
        article: store.blogArticle.article,
        isLoading: store.blogArticle.fetching,
        hasError: store.blogArticle.error !== undefined,
        errorMessage: store.blogArticle.error,
    }
};

class DevOpsDecisions extends React.Component {
    /*constructor() {
        super();
        this.createMarkup = this.createMarkup.bind(this);
        this.showArticle = this.showArticle.bind(this);
    }

    componentWillMount() {
        this.props.dispatch(blogActions.fetchBlog(this.props.match.params.slug));
    }

    createMarkup() {
        return {__html: this.props.article.html};
    }*/

    showArticle() {
        //const {article} = this.props;
        const publishedAt = new Date(2019, 10, 27, 0, 0, 0, 0).toDateString();
        return (
            <div>
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>DevOps Decisions</h1>
                        <p>DevOps is a big thing today. Everybody needs it, everybody knows they need it, but many don’t know how to do it properly or just don’t have the budget for it.</p>
                    </section>
                </div>
                <div className="row-block">
                    <div className="container">
                        <div className="row blog-text justify-content-md-center">
                            <section className="col-md-8">
                                <div className="row">
                                    <section className="col-md-6">
                                        <Link to="/insights"><small><i className="fas fa-arrow-left"/> Back to insights</small></Link>
                                    </section>
                                    <section className="col-md-6 text-md-right">
                                        <p><small>Published on {publishedAt}</small></p>
                                    </section>
                                </div>
                                <div className="blog-post-img">
                                    <img src={blogImg} className="img-fluid" alt="devops decisions" />
                                </div>
                                <p>DevOps is all about your infrastructure from local environment to production and how you manage both development and integration. The infrastructure decisions life cycle is critical to every software company. The importance is more than just having your infrastructure in place. Failing to make the correct decision at the right time will delay the entire development process and as a result will delay time to market too.</p>
                                <p>
                                    What’s worse is that making a bad infrastructure decision will result in technical debt and heavy cost down the road.
                                </p>
                                <p>As we see it at Chiliseed there are 5 main approaches to DevOps decisions and development:</p>
                                <ul>
                                    <li>In-house DevOps</li>
                                    <li>Outsourced DevOps</li>
                                    <li>Open source solutions</li>
                                    <li>High level PaaS solutions</li>
                                    <li><strong>Chiliseed</strong></li>
                                </ul>
                                <p><strong>In-house DevOps</strong> has its advantages but is very expensive and may also be difficult to recruit. Also, with in-house DevOps there is no guarantee that the right direction will be taken. For example, you need to develop a release rollback feature. Does your in-house DevOps team know how to develop this based on best practices? What are the chances they will develop it correctly on the first attempt?</p>
                                <p><strong>Outsourced DevOps</strong> has the same disadvantages as In-house DevOps adding an additional great disadvantage: no long term commitment, which is critical especially for infrastructure decisions.</p>
                                <p><strong>Open source solutions</strong> are often used for infrastructure. However, they always require DevOps resources for full configuration. Also, they have little or no support and limited coverage of your specific needs.</p>
                                <p><strong>PaaS (Platform as a Service) solutions</strong> provide infrastructure setup from a PaaS provider (e.g. Heroku). The disadvantages here are platform-specific integration for your own product which you need to take care of and a hard lock-in, meaning it can be very difficult for you to switch to other providers. Additionally, this approach is usually very pricey especially for larger scale projects.</p>
                                <p>At <strong>Chiliseed</strong> we tackle the infrastructure problem from a different angle. We provide 3 tiers starting with a free option, allowing gradual growth. At any stage, if you decide to "eject" and own a license to the code, you will be able to do so easily. In that way we resolve the "lock-in" issue and you gain full control of your infrastructure. We also provide services to tailor our solution to your product making the integration easy, timely, and affordable.</p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        //const { article } = this.props;
        return (
            <div className="innerblog-page-content">
                <MetaTags>
                    <title>Chiliseed | DevOps decisions</title>
                    <meta name="description" content="DevOps is a big thing today. Everybody needs it, everybody knows they need it, but many don’t know how to do it properly or just don’t have the budget for it." />
                    <meta property="og:title" content="Chiliseed | DevOps Decisions" />
                </MetaTags>
                {this.props.isLoading ? <div className="blog-loading"><i className="fas fa-circle-notch fa-spin"/></div> : this.showArticle() }
            </div>
        );
    }
}

/*const VisibleBlogPost = connect(mapStateToProps)(DevOpsDecisions);
export default VisibleBlogPost;*/

export default DevOpsDecisions;
