import React from "react";
import MetaTags from 'react-meta-tags'

import './AboutUs.css';
import NirImg from './nir.jpg';
import AnaImg from './ana.jpg';
import EstherImg from './esther.jpg';
import ImriImg from './imri.jpg';

class AboutUs extends React.Component {
    render() {
        return (
            <div>
                <MetaTags>
                    <title>Chiliseed | About Us</title>
                    <meta name="description" content="Our team of hand-picked Chiliseeders got together from all corners of the globe in order to provide you with the powerful infrastructure you deserve." />
                    <meta property="og:title" content="Chiliseed | About Us" />
                </MetaTags>
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>Just in case you're curious</h1>
                        <p>
                            Our team of hand-picked Chiliseeders got together from all corners of the globe in order to provide you with the powerful infrastructure you deserve.
                        </p>
                    </section>
                </div>
                <div className="row-block">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-12">
                                <h2>Meet the team</h2>
                            </section>
                        </div>
                        <div className="row text-center team">
                            <section className="col-md-4">
                                <img src={AnaImg} alt="ana" className="img-fluid rounded-circle" />
                                <h4>Ana Trakhtman, CEO</h4>
                                <p>Over 8 years of technical experience from multiple startups.
                                    Proven track record delivering technical solutions - in startups and in a services company she founded.</p>
                            </section>
                            <section className="col-md-4">
                                <img src={EstherImg} alt="esther" className="img-fluid rounded-circle" />
                                <h4>Esther Kozielek, CTO</h4>
                                <p>Over 8 years of experience in  web development.
                                    Experience as a contractor, evolving to co-founding an R&D company.
                                    A background in UI/UX and design.</p>
                            </section>
                            <section className="col-md-4">
                                <img src={NirImg} alt="nir" className="img-fluid rounded-circle" />
                                <h4>Nir Goldberg, CFO & COO</h4>
                                <p>Over a decade  of experience in management of startups.
                                    Ex CFO of Bringg, Axonius & Cymmetria  from early stage to growth stage.</p>
                            </section>
                            {/*<section className="col-md-3 col-sm-6">
                                <img src={ImriImg} alt="imri" className="img-fluid rounded-circle" />
                                <h4>Imri Goldberg, Advisor</h4>
                                <p>VP R&D at Flytrex, Ex CTO of Desti (sold to HERE), Ex CTO of Cymmetria</p>
                            </section>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutUs;
