import React from "react";
import MetaTags from 'react-meta-tags';

import './WhyChiliseed.css';
import artVandelay from './art-vandelay.jpg';


class WhyChiliseed extends React.Component {
    render() {
        return (
            <div className="why-chiliseed-page-content">
                <MetaTags>
                    <title>Chiliseed | Why Chiliseed</title>
                    <meta name="description" content="We know how crucial it is to set things up correctly right form the start, been there done that! Reap the benefits of our depth of knowledge and wealth of experience." />
                    <meta property="og:title" content="Chiliseed | Why Chiliseed" />
                </MetaTags>
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>Rest assured and chill</h1>
                        <p>
                            We know how crucial it is to set things up correctly right form the start, been there done that!<br/>
                            Reap the benefits of our depth of knowledge and wealth of experience.
                        </p>
                    </section>
                </div>
                <div className="why-us row-block">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-3">
                                <i className="fas fa-funnel-dollar" />
                                <h3>Value</h3>
                                <p>
                                    Benefit from cheaper and faster development process
                                </p>
                            </section>
                            <section className="col-md-3">
                                <i className="fas fa-bolt" />
                                <h3>Speed</h3>
                                <p>
                                    Experience quicker time-to-market for your product
                                </p>
                            </section>
                            <section className="col-md-3">
                                <i className="fas fa-bug" />
                                <h3>Precision</h3>
                                <p>
                                    Implement best practices to reduce bugs and long term tech debt
                                </p>
                            </section>
                            <section className="col-md-3">
                                <i className="fas fa-shield-alt" />
                                <h3>Security</h3>
                                <p>
                                    Save time on certification, e.g. ISO 27001, SOC 2, PCI, etc
                                </p>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="testimonials row-block">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-12">
                                <h3>Don't take our word for it, our clients say it best:</h3>
                            </section>
                            <section className="col-md-2">
                                <img className="img-fluid rounded-circle" src={artVandelay} alt="art vandelay" />
                            </section>
                            <section className="col-md-10">
                                <blockquote className="blockquote">
                                    <p>"As part of launching our new product we were about to spend the first six months building the infrastructure
                                        when we discovered Chiliseed. It allowed us to launch ahead of schedule and concentrate on our business.
                                        To top it off, they also saved us lots of money."</p>
                                    <div className="blockquote-footer text-right"><span>Art Vandelay, </span>
                                        <cite title="Testimonial Title">Vandelay Industries</cite>
                                    </div>
                                </blockquote>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WhyChiliseed;
