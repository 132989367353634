import React from 'react';
import MetaTags from 'react-meta-tags'
import { Link } from "react-router-dom";

class EULA extends React.Component {
    render() {
        return (
            <div>
                <MetaTags>
                    <title>Chiliseed | End-User License Agreement for Chiliseed Software</title>
                    <meta name="description" content="End-User License Agreement for Chiliseed Software" />
                    <meta property="og:title" content="Chiliseed | End-User License Agreement for Chiliseed Software" />
                </MetaTags>
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>End-User License Agreement for Chiliseed Software</h1>
                        <p>Last updated: 18/12/2019</p>
                    </section>
                </div>
                <div className="row-block">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-12">
                                <p><strong>IMPORTANT- READ CAREFULLY: This Chiliseed End-User License Agreement ("EULA") is a legal agreement between you (either on your own behalf or on behalf of a legal entity you represent) and Chiliseed Ltd., its affiliates, employees, directors, officers, contractors, and all of their respective authorized representatives, licensors, and assigns ("Chiliseed"). The EULA governs your rights to use any Chiliseed proprietary software and/or updates ("Software"), as well any associated services, software support, programs, tools, user or technical manuals, materials, specifications or other documentation applicable to the Software ("Services"), unless other terms or license apply on such Software or Services.</strong></p>
                                <p><strong>BY DOWNLOADING, INSTALLING, ACCESSING OR USING THE SOFTWARE OR ITS SERVICES, YOU REPRESENT THAT YOU HAVE FULL LEGAL CAPACITY TO ENTER INTO A LEGALLY BINDING AGREEMENT, AND YOU ACCEPT ALL THE TERMS AND CONDITIONS OF THIS EULA. IF YOU ARE ENTERING INTO THIS EULA ON BEHALF OF AN ENTITY, YOU REPRESENT THAT YOU HAVE AUTHORITY TO BIND THAT ENTITY. IF YOU DO NOT AGREE TO THE TERMS OF THIS EULA OR ANY PART HEREOF, YOU ARE MAY NOT DOWNLOAD, INSTALL, ACCESS OR OTHERWISE USE THE SOFTWARE OR SERVICES.</strong></p>
                                <ol>
                                    <li>
                                        <p><strong>GRANT OF LICENSE</strong></p>
                                        <p>Chiliseed grants you a personal, revocable, non-exclusive, non-transferable, non-sublicensable, limited license to download, access and use the Software and  Services for your own personal or internal business use, subject to payment of the applicable fees and compliance with the terms and conditions of this EULA, or any other terms and conditions that Chiliseed may apply on the Software and Services. THIS SOFTWARE IS LICENSED TO YOU, NOT SOLD.</p>
                                    </li>
                                    <li>
                                        <p><strong>RESTRICTIONS AND LIMITATIONS ON USE OF THE SOFTWARE</strong></p>
                                        <p>Except as permitted by Section 1 (Grant of License), you will not and will not allow a third party to: (i) transfer, sublicense, or assign your rights under this license to any other person or entity; or (ii) copy, modify, transmit, adapt, republish, reproduce, print relevant materials, vary or create derivative works based on the Software or Services in whole or part; or (iii) rent, lease, sell or otherwise commercially exploit in any manner the Software or Services; or (iv) maneuver or hack any technical limitations in the Software or Services; or (iv) reverse engineer, decompile, robot, disassemble, debug, hook, harm, spoof or pirate the Software or Services; or (v) probe, scan or test the vulnerability of the Software or its Services or (vi) use any other device, software, or routine to interfere with the proper working of the Software or its Services; or (vii) attempt to gain unauthorized access to any feature, data, or component of the Software or its Services by any means; or (viii) remove, modify, or conceal any product identification, copyright, proprietary, intellectual property notices or other marks on or within the Software or its Services.</p>
                                        <p>You agree that you shall only use the Software in compliance with all applicable laws and regulations of the country or region in which you reside or use the Software and Services.</p>
                                    </li>
                                    <li>
                                        <p><strong>OWNERSHIP</strong></p>
                                        <p>Chiliseed or its licensors retain ownership of all intellectual property rights in and to the Software (including copies, improvements, enhancements, derivative works and modifications thereof) and Services. Your rights to use the Software and Services are limited to those expressly granted by this EULA.  No other rights with respect to the Software, Services or any related intellectual property rights are granted or implied.</p>
                                    </li>
                                    <li>
                                        <p><strong>THIRD PARTY SOFTWARE</strong></p>
                                        <p>In order to access or use the Software or Services (or portions thereof) you may be required to access and/or use third party software, services or products ("Third Party Product"). To the extent that any third party intellectual property is incorporated within the Software and Services, you agree that such third party is a third-party beneficiary of the terms of this EULA to the extent of the third party license to Chiliseed. You must ensure that you are permitted to access and use such Third Party Product and that you comply with all usage rules governing any third party digital storefront from which you acquire such Third Party Product. The Software may also include third party programs or applications that Chiliseed, not the third party, licenses to you under this EULA ("Third Party Programs"). Notices, if any, for the Third Party Product or Programs are included for your information only.</p>
                                    </li>
                                    <li>
                                        <p><strong>UPDATES</strong></p>
                                        <p>Chiliseed may add, update, upgrade, delete or change any part of the Software or Services at any time in Chiliseed’s sole discretion, and any such action will take effect whether a notice was delivered to you or not.</p>
                                    </li>
                                    <li>
                                        <p><strong>SUPPORT SERVICES</strong></p>
                                        <p>Based on your subscription plan, Chiliseed may provide you with support related to the Software and Services which are provided "as is," without warranty of any kind. You may use and receive the support in accordance with Chiliseed’s policies or other materials or guidelines provided to you. Any Third Party Product that is provided to you as part of the Software and Services, shall be subject solely to such third party’s terms and conditions, and any support for such third party product shall be subject solely to the third party’s own support. For the avoidance of any doubt, Chiliseed does not take any responsibilities for supporting such Third Party Product.</p>
                                    </li>
                                    <li>
                                        <p><strong>LIMITED WARRANTY</strong></p>
                                        <p>THE SOFTWARE AND SERVICES ARE LICENSED "AS-IS" AND ONLY IN ACCORDANCE WITH THE DOCUMENTATION PROVIDED BY CHILISEED. YOU EXCLUSIVELY BEAR ANY RISK OR DAMAGE ARISING OF USING IT. YOU ARE SOLELY AND ENTIRELY LIABLE FOR THE PERFORMANCE OR RESULTS YOU MAY OBTAIN BY USING THE SOFTWARE OR SERVICES. CHILISEED GIVES NO EXPRESS WARRANTIES, GUARANTEES OR COMMITMENTS, AND SHALL NOT BE LIABLE FOR LOSSES ARISING FROM YOUR USE OF THE SOFTWARE AND SERVICES AND FOR ANY LOSSES ARISING FROM YOUR INABILITY TO USE THE SOFTWARE AND SERVICES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, CHILISEED AND ITS SUPPLIERS DISCLAIM ALL OTHER WARRANTIES AND CONDITIONS, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT, WITH REGARD TO THE SOFTWARE AND THE PROVISION OF OR FAILURE TO PROVIDE SUPPORT SERVICES.</p>
                                    </li>
                                    <li>
                                        <p><strong>LIMITATION OF LIABILITY</strong></p>
                                        <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL CHILISEED OR ITS SUPPLIERS BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR ANY OTHER PECUNIARY LOSS) ARISING OUT OF THE USE OF OR INABILITY TO USE THE SOFTWARE AND SERVICES. IN ANY CASE, YOU CAN RECOVER FROM CHILISEED ONLY DIRECT DAMAGES UP TO THE AMOUNT YOU PAID IF AT ALL TO CHILISEED FOR THE SOFTWARE OR SERVICES DURING THE TWELVE MONTHS PRECEDING THE EVENT GIVING RISE TO YOUR CLAIM. THIS LIMITATION APPLIES: (A) TO ANYTHING RELATED TO THE SOFTWARE AND SERVICES; AND (B) EVEN IF CHILISEED HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR CHILISEED KNEW OR SHOULD HAVE KNOWN ABOUT THE POSSIBILITY OF THE DAMAGES. THE ABOVE LIMITATION OR EXCLUSION SHALL NOT APPLY TO THE EXTENT THAT YOU MAY HAVE ADDITIONAL CONSUMER RIGHTS OR STATUTORY GUARANTEES UNDER ANY LAWS OF YOUR PERMANENT RESIDENCE OR IF YOUR COUNTRY DOES NOT ALLOW CHILISEED TO EXCLUDE OR LIMIT ITS LIABILITY, WHICH THIS EULA CANNOT CHANGE OR AFFECT.</p>
                                    </li>
                                    <li>
                                        <p><strong>FEES AND SUBSCRIPTIONS</strong></p>
                                        <p>You acknowledge that the license granted to you in this EULA is subject to payment of the applicable fees which may apply to the Software and/or Services (or portions thereof).  If you are acquiring the Software and Services (or any portion thereof) on a subscription basis or as part of a free trial, then the license shall apply only during the time period you have paid for.</p>
                                    </li>
                                    <li>
                                        <p><strong>PRIVACY POLICY AND CONSENT TO PROCESS PERSONAL INFORMATION</strong></p>
                                        <p>Chiliseed is committed to maintaining the privacy and security of your personal information. Collecting and processing your personal information may be necessary to provide you with our relevant Software and Services, and to improve overall security for you. Therefore, you expressly agree that Chiliseed is permitted to collect and process (including copy, backup, store and use) any personal or technical information you provide in relation to your access and/or use of the Software and services,  subject to the Chiliseed <Link to="/privacy-policy">Privacy Policy</Link>.</p>
                                    </li>
                                    <li>
                                        <p><strong>TERM AND TERMINATION</strong></p>
                                        <p>Without prejudice to any other rights, Chiliseed is permitted to terminate this EULA or your rights under this EULA at any time if you fail to comply with the terms and conditions of this EULA. The license granted to you under this EULA will automatically  be terminated upon: (a) the end of any trial time period or subscription term that is not renewed or replaced with an alternative offering by Chiliseed, (b) when you stop paying any applicable fees or breaching this EULA, (c) Chiliseed stopped to make the Software or Services generally available, or (d) when you are given  a notice of such termination by Chiliseed. Upon such termination, all rights granted to you under this license shall be terminated, and you must immediately stop using the Software and any Services. The terms of this Section 11 shall survive any termination of this EULA.</p>
                                    </li>
                                    <li>
                                        <p><strong>ENTIRE AGREEMENT</strong></p>
                                        <p>If any portion of this EULA is found to be void or unenforceable, the remaining provisions of the EULA shall remain in full force and effect. Except as expressly stated or as expressly amended in a signed agreement, this EULA and any terms and conditions for supplements, updates and services that you use, constitute the entire legal agreement for the Software and Services.</p>
                                    </li>
                                    <li>
                                        <p><strong>GOVERNING LAW AND JURISDICTION</strong></p>
                                        <p>This EULA shall be governed and construed in accordance with the laws of the State of Israel without giving effect to any conflict or choice of law provisions. You and Chiliseed consent to the exclusive jurisdiction and venue of the competent courts of Tel Aviv, Israel, for all disputes arising out of or relating to this EULA. Notwithstanding the foregoing, Chiliseed may seek injunctive relief in any court of competent jurisdiction.</p>
                                        <p>Should any provision of this EULA be rendered or declared invalid by a court of competent jurisdiction, such invalidation of such provision of this EULA should not invalidate the remaining provisions hereof, and they shall remain in full force and effect.</p>
                                    </li>
                                    <li>
                                        <p><strong>LEGAL EFFECT</strong></p>
                                        <p>This EULA describes certain legal rights and obligations between you and Chiliseed. You may have other rights or obligations under any law or regulation of your permanent residence. This EULA does not affect or change your rights or obligations under the laws or regulations of your permanent residence, if these laws or regulations do not permit it to do so.</p>
                                    </li>
                                    <li>
                                        <p><strong>CONTACT</strong></p>
                                        <p>If you have any questions (or comments) concerning this EULA, you are welcome to contact us via <Link to="/contact">chiliseed.com/contact</Link> and we will make an effort to reply within a reasonable timeframe.</p>
                                    </li>
                                </ol>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EULA;
