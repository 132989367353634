import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from 'react-meta-tags';

import '../InnerBlog/InnerBlog.css';
import blogImg from '../images/blog3.jpg';
import * as blogActions from "../../../actions/blogActions";


const mapStateToProps = store => {
    return {
        article: store.blogArticle.article,
        isLoading: store.blogArticle.fetching,
        hasError: store.blogArticle.error !== undefined,
        errorMessage: store.blogArticle.error,
    }
};

class TimeToMarket extends React.Component {
    /*constructor() {
     super();
     this.createMarkup = this.createMarkup.bind(this);
     this.showArticle = this.showArticle.bind(this);
     }

     componentWillMount() {
     this.props.dispatch(blogActions.fetchBlog(this.props.match.params.slug));
     }

     createMarkup() {
     return {__html: this.props.article.html};
     }*/

    showArticle() {
        //const {article} = this.props;
        const publishedAt = new Date(2019, 11, 11, 0, 0, 0, 0).toDateString();
        return (
            <div>
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>Time to Market and Ready-to-use Code</h1>
                        <p>Time to market means a great deal to hi-tech companies and their projects.<br/>
                            In many cases, it’s the difference between success and failure.</p>
                    </section>
                </div>
                <div className="row-block">
                    <div className="container">
                        <div className="row blog-text justify-content-md-center">
                            <section className="col-md-8">
                                <div className="row">
                                    <section className="col-md-6">
                                        <Link to="/insights"><small><i className="fas fa-arrow-left"/> Back to insights</small></Link>
                                    </section>
                                    <section className="col-md-6 text-md-right">
                                        <p><small>Published on {publishedAt}</small></p>
                                    </section>
                                </div>
                                <div className="blog-post-img">
                                    <img src={blogImg} className="img-fluid" alt="true cost of devops infrastructure" />
                                </div>
                                <p>Developing a product in a timely manner ensures that you are the first to the market, with more time to find the market fit and gain market share. Also, a faster development process lowers the overall costs.</p>
                                <p>Given that up to 40% of R&D time and cost does not directly contribute to your product’s business logic or user experience but is spent on infrastructure, it might be frustrating. However, it is absolutely critical to deal with the infrastructure correctly and effectively as early as possible so that you can get to the market before your competitors. The conventional practice is to seamlessly integrate the software development with efficient DevOps development, as is so eloquently explained in <a href="https://aws.amazon.com/devops/what-is-devops/" target="_blank">this article on AWS</a>.</p>
                                <p><strong>Why is infrastructure so complicated and what impact does it have on the time to market?</strong></p>
                                <p>Like most things in life, experience is what matters with regards to infrastructure. Unlike the product and business logic, which are unique for each startup (that's the definition of a "startup"), the infrastructure is usually not unique, rather quite similar, and in some cases practically identical to other products’ infrastructure.</p>
                                <p>The issue is that due to the typically fast paced building process of a product, the infrastructure tasks are often given to inexperienced developers and this has high costs in the long run, especially as the product begins to scale.</p>
                                <p>As we see it at Chiliseed, the solution lies in having ready-to-use code that has already been deployed and tested many times in production and is easily scalable. This doesn't necessarily always mean that no adjustments will be required for your product, but at least most of it is ready and therefore it should be quite easy and fast to tweak. In that way, you will be able to save your most expensive resources and have your infrastructure taken care of by professionals that have already dealt with the difficult challenge of infrastructure setup many times and have invested that priceless knowledge into a quick, ready-to-use and fully-featured environment solution.</p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        //const { article } = this.props;
        return (
            <div className="innerblog-page-content">
                <MetaTags>
                    <title>Chiliseed | Time to market and ready-to-use code</title>
                    <meta name="description" content="Time to market means a great deal to hi-tech companies and their projects. In many cases, it’s the difference between success and failure." />
                    <meta property="og:title" content="Chiliseed | Time to Market and Ready-to-use Code" />
                </MetaTags>
                {this.props.isLoading ? <div className="blog-loading"><i className="fas fa-circle-notch fa-spin"/></div> : this.showArticle() }
            </div>
        );
    }
}

/*const VisibleBlogPost = connect(mapStateToProps)(DevOpsDecisions);
 export default VisibleBlogPost;*/

export default TimeToMarket;
