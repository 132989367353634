import React from "react";
import MetaTags from 'react-meta-tags'

import './Features.css';

class Services extends React.Component {
    render() {
        return (
            <div className="services-page-content">
                <MetaTags>
                    <title>Chiliseed | Features</title>
                    <meta name="description" content="Our years of experience have allowed us to amass invaluable knowledge and expertise in infrastructure and its related fields. Go ahead and take advantage of all that!" />
                    <meta property="og:title" content="Chiliseed | Features" />
                </MetaTags>
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>What we bring to the table</h1>
                        <p>
                            Our years of experience have allowed us to amass invaluable knowledge and expertise in infrastructure and its related fields.<br/>
                            Go ahead and take advantage of all that!
                        </p>
                    </section>
                </div>
                <div className="our-services row-block">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-4">
                                {/*<div className="tile">
                                    <i className="fa fa-laptop-code" aria-hidden="true" />
                                    <h3>Local Environment</h3>
                                    <p>
                                        Dockerized local development environment that
                                        closely reflects your production environment.
                                    </p>
                                </div>*/}
                                <div className="tile">
                                    <i className="fa fa-laptop-code" aria-hidden="true" />
                                    <h3>Monitoring</h3>
                                    <p>
                                        Real-time insights into your infrastructure,
                                        detailing deployed versions and uptime status.
                                    </p>
                                </div>
                            </section>
                            <section className="col-md-4">
                                <div className="tile">
                                    <i className="fas fa-cogs" aria-hidden="true" />
                                    <h3>CI/CD</h3>
                                    <p>
                                        Automatic testing and build for pull requests or commits on
                                        deployment branches. Deploy with confidence
                                        on successful builds.
                                    </p>
                                </div>
                            </section>
                            <section className="col-md-4">
                                <div className="tile">
                                    <i className="fas fa-server" aria-hidden="true" />
                                    <h3>Production Environment</h3>
                                    <p>
                                        Dockerized production environment on top of AWS, including secure routing,
                                        logging and monitoring and many other components for a stable production environment.
                                    </p>
                                </div>
                            </section>
                        </div>
                        <div className="row">
                            <section className="col-md-12">
                                <h3>...and that's not all. Many more features, including:</h3>
                                <ul>
                                    <li>Basic project functionality, standardized API</li>
                                    <li>Deployment to AWS and GitHub integration</li>
                                    <li>Testing, linters and other code checking tools, as part of CI/CD</li>
                                    <li>Basic email integration (SES)</li>
                                    <li>Background tasks</li>
                                    <li>Much, much more!</li>
                                </ul>
                                <h5>Don't see your dream feature? <a href="/contact">Let us know</a> what you are after and we will seriously consider it.</h5>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;
