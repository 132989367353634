import React from 'react';
import MetaTags from 'react-meta-tags'
import { Link } from "react-router-dom";

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <div>
                <MetaTags>
                    <title>Chiliseed | Privacy Policy</title>
                    <meta name="description" content="Chiliseed Privacy Policy" />
                    <meta property="og:title" content="Chiliseed | Privacy Policy" />
                </MetaTags>
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>Chiliseed Privacy Policy</h1>
                        <p>Last updated: 18/12/2019</p>
                    </section>
                </div>
                <div className="row-block">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-12">
                                <p>Chiliseed Ltd. ("Chiliseed", "we", "us" or "our") highly values and respects your privacy. This privacy policy ("Policy") outlines which information we collect when you ("you" or "your") access and use the Site as well as the content, products, software and services (which, together, are referred to as the "Services"), and how we protect and use it. The Privacy Policy also wishes to make sure that you are aware of the options available to you when you access and use our Services.</p>
                                <p>Capitalized terms which are not defined herein shall have the meaning ascribed to them in Chiliseed’s <Link to="/terms-of-use">Terms of Use</Link>, to which this Privacy Policy is incorporated.</p>
                                <p>This Privacy Policy outlines how we collect and use information about you, through the Site or through other means, within our Services. Your privacy is important to us. We will act as described in this Privacy Policy, in accordance with applicable law, including the Privacy Protection Law, 1981.</p>
                                <p>This Privacy Policy applies to identified information or identifiable information by reasonable means. In general, you do not have a legal obligation to provide the information specified below, but failure to do so may prevent us from providing you with certain Services. The information provided by you will be stored in Chiliseed’s database and will be kept for the time required for Chiliseed to execute the objectives permitted by law and in accordance with this Privacy Policy.</p>
                                <p>We may update this Privacy Policy from time to time, according to necessities and changes in the law. Note these changes, since they apply to you. Our updated Privacy Policy will appear on the Site at all times.</p>
                                <p><strong>PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING THE SITE AND SERVICES. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY YOU MAY NOT DOWNLOAD, INSTALL, ACCESS OR OTHERWISE USE OUR SITE AND SERVICES.</strong></p>
                                <ol>
                                    <li>
                                        <p><strong>WHAT INFORMATION DO WE COLLECT AND HOW DO WE COLLECT IT?</strong></p>
                                        <ul>
                                            <li><strong>Contact Information</strong> - When you register to the Services, you may be required to submit certain details about yourself. This may include, among others, your name, phone number, email address, name of organization, your position in the organization, passwords, credit card details, third party cloud provider account details and managed infrastructure details. Such information will be provided by you directly as part of filling out the details on the Site, registering for the Services or by contacting our customer service.</li>
                                            <li><strong>Technical Information</strong> - We will not share or sell your information for marketing purposes. We will share certain personal information with our partners and providers in order run our Services and to resolve an incident. We may also share non-personally identifiable information in order to improve our modeling and perform aggregate reporting, including by use of Google Analytics and other related services provided by Google (for more information on how Google Analytics collects and processes data visit: <a href="https://policies.google.com/privacy/partners" target="_blank">policies.google.com/privacy/partners</a>).<br/>
                                                We use Cookies for data security needs for proper and ongoing Site operations, Services’ optimization, information verification and in order to specify the Services to your personal preferences. For example, the use of Cookies helps identify you when you log back to our Site. Using Cookies, your activity on the Site will be monitored and information will be automatically collected, such as an IP address, duration of your visit to the Site, your operating system’s version, browser type, location and more. The Cookies will also serve us for marketing needs, including personalized marketing. In addition, we may use network signals or other monitoring technologies. You can stop the collection of information with Cookies by changing the settings in your browser. If you block Cookies, some Services may not be available to you, or you may need to re-enter details. Changing these settings is under your responsibility. </li>
                                            <li><strong>Information about others</strong> - When you provide us with information about others, it is your responsibility to make sure that it is done in accordance with the applicable law, including the Privacy Protection Law, 1981, and Communications Law (Telecommunications and Broadcasts) ,1982.</li>
                                            <li><strong>Aggregated information</strong> - We may also gather statistical and aggregated information originating from our users which may be combined with additional non-identifiable information collected from other sources, regarding the use of the Services. This information will be anonymous and will not allow, by reasonable means, to identify – or to be attributed to – a specific user. We will use this information for the purposes of internal research and development, and we also may share it with our business partners, affiliates or other third parties including advisors, advertisers and investors, for the purpose of conducting a general business analysis. To avoid doubt, any non-personal information connected or linked to any personal information shall be deemed as personal information as long as such connection or linkage exists.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><strong>HOW WILL THE INFORMATION BE USED?</strong></p>
                                        <p>We will use, store and process your information for the following purposes:</p>
                                        <ul>
                                            <li>Ongoing operation and performance of the Services and our internal course of business.</li>
                                            <li>Support and communication</li>
                                            <li>In order to comply with all applicable laws, regulations and legal processes, protect your privacy, prevent illegal activities, enforce applicable terms of service and protect Chiliseed’s rights, your rights as well as other third-parties’ rights.</li>
                                            <li>Additional legitimate interests, including: Preventing and responding to fraud, inappropriate use or abuse of the Services; preventing or addressing technical or security issues; fulfilling our commitments to our partners or other third-parties; internal research for the purpose of evaluating the use of the Services, developing new features for the Services and improving user experience; marketing to you or advertising of the Services; ensuring the protection of our rights, security and property (and those of our partners, contractors and users); and various legal purposes, such as the establishment, exercise or defense of a legal claim.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><strong>TRANSFER OF INFORMATION TO THIRD PARTIES</strong></p>
                                        <p>Chiliseed may transfer your information to third parties, whether in Israel or abroad, in the following cases:</p>
                                        <ul>
                                            <li>For the purposes set out in this Privacy Policy, for the purposes permitted by law, and for the implementation of our legitimate interests, such as preventing fraud and other illegal activities. Such information transfer shall be made subject to the third party’s obligations to maintain privacy and data security at the level required according to the type of information transferred.</li>
                                            <li>When we engage third parties to process data on our behalf. Such third parties' use will be subject to our explicit instructions and in compliance with this Privacy Policy.</li>
                                            <li>As part of the sale of Chiliseed’s business (in whole or in part), provided that such third party complies with the provisions of the law regarding the protection of privacy and information security.</li>
                                            <li>If we have received a judicial order instructing us to do so or if there is a requirement to do so by law.</li>
                                            <li>So that we can defend ourselves against lawsuits.</li>
                                            <li>Enforce this Privacy Policy or the Terms of Use, including in order to investigate potential breaches thereof; protect our rights, property or safety, or those of our users.</li>
                                            <li>So that we can defend ourselves against lawsuits.</li>
                                            <li>We may also share aggregated or non-personally identifiable information that we collect and share as broadly described above under "what data we collect" section.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p><strong>ADVERTISEMENTS AND DIRECT MAIL</strong></p>
                                        <p>The information will also be used for marketing, advertising and direct mail purposes by us or anyone on our behalf, as well as information on products and services of third parties provided in cooperation with us, including personalized advertising.</p>
                                        <p>We will be allowed to send you direct mail and advertisements regarding our Services (and subject to your consent, also in connection with other services or products), by any means of communication, including by e-mail, SMS, automated dialing system and social networks. You can request to unsubscribe from the mailing list for advertisements and direct mail mailing list, also by contacting Chiliseed. Note that even after unsubscribing, you may still receive some Mailings we must and/or allowed to send to you by applicable law, even without your consent.</p>
                                    </li>
                                    <li>
                                        <p><strong>YOUR RIGHTS</strong></p>
                                        <p>We value your privacy and control over your information. Subject to applicable law, you may, at any time, request to access your information in order to understand what information is being processed by Chiliseed. You may ask us to change and update your information. You may also ask us to erase your personal information, if it is inaccurate or processed in violation of this Privacy Policy except for data that we are required to maintain under applicable laws and regulations.</p>
                                    </li>
                                    <li>
                                        <p><strong>EXTERNAL LINKS AND THIRD-PARTY SERVICES</strong></p>
                                        <p>The Services may contain integration with third-party service providers and links to third party sites or other apps or services that are not owned or operated by Chiliseed. Similarly, it is possible that you will use other services to interact or interface with the Services (for example, by accessing the Services via your social network account). This Privacy Policy only applies to Chiliseed’s Services and does not apply to any third-party sites, apps, platforms or any other services. The use of these technologies by these third parties is subject to their own privacy policies and is not covered by this Privacy Policy.</p>
                                        <p>WE ARE NOT RESPONSIBLE FOR THE PRIVACY PRACTICES OR TERMS OF ANY THIRD PARTIES.</p>
                                    </li>
                                    <li>
                                        <p><strong>INFORMATION SECURITY</strong></p>
                                        <p>Chiliseed takes appropriate data security measures for the type of information it possesses and in accordance with the provisions of the law. For this purpose, Chiliseed uses reasonable and acceptable security measures that comply with custom standards in this field. Although we do our best to protect your information, unfortunately, no method of transmitting or storing electronic data is ever completely secure As long as Chiliseed takes reasonable security measures, it shall not be liable for any damage caused by such unauthorized penetration and transfer of information to a third party as a result of such penetration. It is also clarified that you are responsible for taking appropriate information security measures such as the use of anti-virus measures, software updates, and the like.</p>
                                        <p>If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you might have with us has been compromised), please immediately notify us of the problem by contacting us in accordance with the "Contact" section below.</p>
                                    </li>
                                    <li>
                                        <p><strong>PROTECTION OF CHILDREN’S PRIVACY</strong></p>
                                        <p>The Services are not directed to individuals under the age of eighteen (18).</p>
                                        <p>IF YOU ARE UNDER 18 YEARS OF AGE, YOU MAY NOT USE OR ACCESS THE SERVICES AT ANY TIME OR IN ANY MANNER.</p>
                                        <p>Protecting the privacy of young children is especially important. We will not knowingly collect or maintain personal information from individuals under the age of sixteen (16). If we learn that personal information of persons under 16 years of age has been collected by us on or through the Services, we may take the appropriate steps to delete such information.</p>
                                    </li>
                                    <li>
                                        <p><strong>GOVERNING LAW AND JURISDICTION</strong></p>
                                        <p>This Privacy Policy shall be governed by the laws of the State of Israel, without respect to its conflict of laws principles. We each agree to submit to the exclusive jurisdiction of the courts of Tel-Aviv, Israel.</p>
                                    </li>
                                    <li>
                                        <p><strong>CHANGES TO THE PRIVACY POLICY</strong></p>
                                        <p>We are constantly seeking to improve our privacy practices. Accordingly, we may amend, change, update or modify this Privacy Policy from time to time.</p>
                                        <p>Please take a look at the "LAST UPDATED" legend at the top of this page to see when this Privacy Policy was last revised. Any such revision or modification will become effective immediately upon posting of the revised Terms on our website.</p>
                                        <p>Your continued use of the Services following the posting of revised Privacy Policy means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>
                                    </li>
                                    <li>
                                        <p><strong>CONTACT</strong></p>
                                        <p>If you have any questions (or comments) concerning this Privacy Policy, you are welcome to contact us via <Link to="/contact">chiliseed.com/contact</Link> and we will make an effort to reply within a reasonable timeframe.</p>
                                    </li>
                                </ol>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;
