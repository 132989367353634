import React from "react";
import { Link } from "react-router-dom";


class BlogArticle extends React.Component {
    render() {
        const {post} = this.props,
            blogPostURI = post.slug;

        return (
            <div className="row">
				<section className="col-md-12">
					<div className="clearfix blog-row">
						<section className="col-md-6">
							<a className="blog-img" href={blogPostURI}>
								<img className="img-fluid" alt="blog" src={post.image}/>
							</a>
						</section>
						<section className="col-md-6">
							<div className="blog-archive">
								<a href={blogPostURI}>
									<h2>{post.title}</h2>
								</a>
								<p>{post.summary}</p>
								<a className="btn btn-primary" href={blogPostURI}>
									Read More
								</a>
							</div>
						</section>
					</div>
				</section>
			</div>
        );
    }
}

export default BlogArticle;
