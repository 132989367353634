import React from "react";
import { Link } from "react-router-dom";
import { BrowserView, isBrowser } from "react-device-detect";
import MetaTags from 'react-meta-tags';

import './Home.css';
import awsLogo from './aws.png';
import terraformLogo from './terraform.png';
import dockerLogo from './docker.png';
import pythonLogo from './python.png';
import jenkinsLogo from './jenkins.png';
import travisLogo from './travis.png';
import homeAboutPic from './home-about.png';


class Home extends React.Component {
    render() {
        return (
            <div className="home-page-content">
                {/*<MetaTags>
                    <title>Chiliseed - Unified infrastructure from local to production</title>
                    <meta name="description" content="Some description." />
                    <meta property="og:title" content="Chiliseed - Unified infrastructure from local to production" />
                </MetaTags>*/}
                <MetaTags>
                    <title>Chiliseed - Production-ready environment in 48 hours on AWS</title>
                    <meta name="description" content="A ready-to-use production environment, running in your own cloud, fully set up and ready to be deployed, based on best practices." />
                    <meta property="og:title" content="Chiliseed - Production-ready environment in 48 hours on AWS" />
                </MetaTags>
                <div className="landing-info">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-8 mx-auto text-center">
                                {/*<h1>
                                    Unified infrastructure from local to production
                                </h1>*/}
                                <h1>
                                    Production-ready environment<br/>in 48 hours on AWS
                                </h1>
                                <p>A ready-to-use production environment, running in your own cloud, <br/>
                                    fully set up and ready to be deployed, based on best practices.</p>
                                <Link className="contact-us btn btn-primary" to="/get-started">Let's get building</Link>
                            </section>
                            {/*<section className="col-md-6">
                                <img className="img-fluid" alt="cloud" src={cloudImage}/>
                            </section>*/}
                        </div>
                    </div>
                </div>
                <div className="row-block tech-stack">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="aws logo" src={awsLogo}/>
                            </section>
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="docker logo" src={dockerLogo}/>
                            </section>
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="jenkins logo" src={jenkinsLogo}/>
                            </section>
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="travis logo" src={travisLogo}/>
                            </section>
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="python logo" src={pythonLogo}/>
                            </section>
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="terraform logo" src={terraformLogo}/>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="row-block home-about">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-6">
                                <h2>Focus on your business logic</h2>
                                <p>
                                    Everybody is developing the same "Box" for their own startup!
                                </p>
                                <p>
                                    15%-40% of startup R&D is identical across different companies in the same general vertical (e.g. SaaS).
                                </p>
                                <p>
                                    Let us take care of your infrastructure so that you can focus on your product.
                                </p>
                            </section>
                            <section className="col-md-6">
                                <img className="img-fluid" alt="about us" src={homeAboutPic}/>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="row-block home-solution text-center">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-12">
                                <h3>Get it right the first time round</h3>
                            </section>
                        </div>
                        <div className="row">
                            <section className="col-md-3">
                                <i className="fas fa-microscope" />
                                <h4>Testing and code analysis</h4>
                                <p>
                                    As part of your CI/CD pipeline, ensure your logic works exactly as planned and prevent errors before they arise.
                                </p>
                            </section>
                            <section className="col-md-3">
                                <i className="fas fa-chart-line" />
                                <h4>Monitoring and logging</h4>
                                <p>
                                    Have a production environment running from the outset with in-depth insight into the resources you manage.
                                </p>
                            </section>
                            <section className="col-md-3">
                                {/*<i className="far fa-file-code" />*/}
                                <i className="fab fa-aws" />
                                <h4>AWS Simplified</h4>
                                <p>
                                    The AWS experience you have been waiting for - simple, clear and intuitive.
                                </p>
                            </section>
                            <section className="col-md-3">
                                <i className="fas fa-cogs" />
                                <h4>CI/CD pipeline</h4>
                                <p>
                                    Automate your software delivery process and control deployment environments.
                                </p>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="row-block use-cases">
                    <div className="container">
                        <div className="row">
                            <section className="col-lg-6">
                                <div className="case">
                                    <i className="fas fa-bookmark" />
                                    <h2>Migrating from Heroku to AWS</h2>
                                    <p>Hailio* is a taxi service app. Understandably they have massive amounts of data and traffic that need to be served in a timely and precise manner. Not wishing to drown in the depths of DevOps they opted for Heroku to manage their infrastructure.<br/>
                                    However, being a large scale application their monthly fees started to pile up exponentially.</p>
                                    <p>That’s when Chiliseed was introduced. </p>
                                    <p>Chiliseed allowed them to get all the features they currently have on Heroku directly on AWS with no abstraction layer and at considerably lower fees. After just about a month of migration they noticed how their costs were reduced by over 40%!<br/>
                                    Continued support also ensured constant maintenance and integration of new features.</p>
                                </div>
                            </section>
                            <section className="col-lg-6">
                                <div className="case">
                                    <i className="fas fa-bookmark" />
                                    <h2>Leaving the burden to Chiliseed</h2>
                                    <p>Hiflex* is a startup company that deals with food delivery. After starting out with a very simple cloud setup they quickly realized that a more robust solution would be necessary but they did not have a dedicated DevOps team and therefore turned to Chiliseed to provide them with all their infrastructure needs.</p>
                                    <p>From dockerizing their project on ECS, standardized API, deployment to AWS and GitHub integration, CI/CD to background tasks and much more - Chiliseed had them covered.</p>
                                    <p>The process was quick and simple yet still allowed them full control of their AWS account, thereby greatly reducing fees and speeding up the time-to-market of their product.</p>
                                </div>
                            </section>
                            <section className="col">
                                <p className="small-note"><small>*Company names have been changed for confidentiality purposes.</small></p>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="row-block home-blog">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-4">
                                <h2>Featured insight</h2>
                            </section>
                            <section className="col-md-8">
                                <div className="latest-blog">
                                    <h3>DevOps Decisions</h3>
                                    <p>DevOps is a big thing today. Everybody needs it, everybody knows they need it, but many don’t know how to do it properly or just don’t have the budget for it.
                                    </p>
                                    <Link className="btn btn-primary" to="insights/devops-decisions">Read more</Link>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
