import React from "react";
import MetaTags from 'react-meta-tags'

import './Pricing.css';

class Pricing extends React.Component {
    render() {
        return (
            <div className="contact-page-content">
                <MetaTags>
                    <title>Chiliseed | Pricing</title>
                    <meta name="description" content="Your time and money is precious. Start your project off with zero costs and expand as required. Get started with a ready-to-use environment that guarantees speed and value." />
                    <meta property="og:title" content="Chiliseed | Pricing" />
                </MetaTags>
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>The facts and figures</h1>
                        <p>
                            Your time and money is precious. Start your project off with zero costs and expand as required.<br/>
                            Get started with a ready-to-use environment that guarantees speed and value.
                        </p>
                    </section>
                </div>
                <div className="row-block pricing">
                    <div className="container">
                        <div className="row">
                            <section className="col-lg-4">
                                <div className="card mb-5 mb-lg-0">
                                    <div className="card-body">
                                        <h5 className="card-title text-muted text-uppercase text-center">Free</h5>
                                        <h6 className="card-price text-center">$0<span className="period">/month</span></h6>
                                        <hr/>
                                        <ul className="fa-ul">
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>One environment</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Default database (PostgreSQL) with one read/replica</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>ECS + ECR</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>CLI deployment tool</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Frontend environment for SPA (React/Angular)</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>DevOps services/consulting, configuration and support</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>CI/CD - Jenkins</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Integration with GitHub/GitLab</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Testing, linters and other code checking tools, as part of CI/CD (Python)</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Local environment</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Background tasks</li>
                                        </ul>
                                        <a href="/contact" className="btn btn-block btn-primary text-uppercase">Get Started</a>
                                    </div>
                                </div>
                            </section>
                            <section className="col-lg-4">
                                <div className="card mb-5 mb-lg-0">
                                    <div className="card-body">
                                        <h5 className="card-title text-muted text-uppercase text-center">Standard</h5>
                                        <h6 className="card-price text-center">$499<span className="period">/month</span></h6>
                                        <hr/>
                                        <ul className="fa-ul">
                                            <li><span className="fa-li"><i className="fas fa-check"/></span><strong>Up to 3 environments</strong></li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>PostgreSQL/MySQL with unlimited number of read/replica</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>ECS + ECR</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>CLI deployment tool</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Frontend environment for SPA (React/Angular)</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>10 hours per year of DevOps services/consulting, configuration and support</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>CI/CD - Jenkins</li>

                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Integration with GitHub/GitLab</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Testing, linters and other code checking tools, as part of CI/CD (Python)</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Local environment</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Background tasks</li>
                                        </ul>
                                        <a href="/contact" className="btn btn-block btn-primary text-uppercase">Get Started</a>
                                    </div>
                                </div>
                            </section>
                            <section className="col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title text-muted text-uppercase text-center">Premium</h5>
                                        <h6 className="card-price text-center">$2499<span className="period">/month</span></h6>
                                        <hr/>
                                        <ul className="fa-ul">
                                            <li><span className="fa-li"><i className="fas fa-check"/></span><strong>Unlimited
                                                environments</strong></li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>PostgreSQL/MySQL with unlimited number of read/replica</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>ECS + ECR</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>CLI deployment tool</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Frontend environment for SPA (React/Angular)</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span><strong>30 hours per year</strong> of DevOps services/consulting, configuration and support</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>CI/CD - Jenkins</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Integration with GitHub/GitLab</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Testing, linters and other code checking tools, as part of CI/CD (Python)</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Local environment</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Background tasks</li>
                                        </ul>
                                        <a href="/contact" className="btn btn-block btn-primary text-uppercase">Get Started</a>
                                    </div>
                                </div>
                            </section>
                        </div>
                        {/*<div className="row-block pricing code">
                    <div className="container">
                        <div className="row">
                            <section className="col-lg-12">
                                <h2>Chiliseed Code</h2>
                                <p>This Chiliseed product provides you with a license to the code for a production-ready environment on top of ECS.</p>
                                <div className="card mb-5 mb-lg-0">
                                    <div className="card-body">
                                        <h5 className="card-title text-muted text-uppercase text-center">One-time Fee</h5>
                                        <h6 className="card-price text-center">$5999</h6>
                                        <hr/>
                                        <ul className="fa-ul">
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>VPC setup</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Networking setup with private and public subnets, NAT gateway and internet gateway</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Security groups setup</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>ALB setup with target groups</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>RDS setup with read replica</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>EC2 setup with autoscaling group</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>ACM - SSL certificate</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Route 53</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>User for deployment jobs, with all required privileges</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>ECS instance IAM role</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>ECR repos</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>CloudWatch log streams</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Store parameter with user defined environment variables</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>S3, Route 53, CloudFront for frontend SPA</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>S3 for CloudFront access logs</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Up to 10 hours of DevOps services/consulting, configuration, integration</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Control layer - DynamoDB setup as a lock, if needed, to prevent simultaneous changes of the environment</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Example deployment configuration to ECS, using docker-compose files</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Example script for deployment to ECS</li>
                                        </ul>
                                        <a href="/contact" className="btn btn-block btn-primary text-uppercase">Get Started</a>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>*/}
                {/*<div className="row-block pricing">
                    <div className="container">
                        <div className="row">
                            <section className="col-lg-12">
                                <h2>Chiliseed Core</h2>
                                <p>Behold the full-scale unified infrastructure service that includes all the Chiliseed Code features along with many more delightful goodies.</p>
                            </section>
                            <section className="col-lg-4">
                                <div className="card mb-5 mb-lg-0">
                                    <div className="card-body">
                                        <h5 className="card-title text-muted text-uppercase text-center">Free</h5>
                                        <h6 className="card-price text-center">$0<span className="period">/month</span></h6>
                                        <hr/>
                                        <ul className="fa-ul">
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Up to 2 installations</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Organized terraform that builds VPC, ECS Cluster, DB (production environment on AWS), deployer user, parameters store</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>CLI tool that builds/updates the infrastracture on AWS</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Frontend (Cloudwatch login, Route 53, Cloudfront, S3, IM user roles)</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Deployment tool</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Basic security groups</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>DevOps services/consulting, configuration, integration</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>CI/CD - Jenkins</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Basic project functionality</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Testing, linters and other code checking tools, as part of CI/CD</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Basic email integration (SES)</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Background tasks</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Github integration</li>
                                        </ul>
                                        <a href="/contact" className="btn btn-block btn-primary text-uppercase">Get Started</a>
                                    </div>
                                </div>
                            </section>
                            <section className="col-lg-4">
                                <div className="card mb-5 mb-lg-0">
                                    <div className="card-body">
                                        <h5 className="card-title text-muted text-uppercase text-center">Standard</h5>
                                        <h6 className="card-price text-center">$499<span className="period">/month</span></h6>
                                        <hr/>
                                        <ul className="fa-ul">
                                            <li><span className="fa-li"><i className="fas fa-check"/></span><strong>Up to 10 installations</strong></li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Organized terraform that builds VPC, ECS Cluster, DB (production environment on AWS), deployer user, parameters store</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>CLI tool that builds/updates the infrastracture on AWS</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Frontend (Cloudwatch login, Route 53, Cloudfront, S3, IM user roles)</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Deployment tool</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Basic security groups</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>10 hours per year of DevOps services/consulting, configuration, integration</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>CI/CD - Jenkins</li>

                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Basic project functionality</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Testing, linters and other code checking tools, as part of CI/CD</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Basic email integration (SES)</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Background tasks</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"/></span>Github integration</li>
                                        </ul>
                                        <a href="/contact" className="btn btn-block btn-primary text-uppercase">Get Started</a>
                                    </div>
                                </div>
                            </section>
                            <section className="col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title text-muted text-uppercase text-center">Premium</h5>
                                        <h6 className="card-price text-center">$2499<span className="period">/month</span></h6>
                                        <hr/>
                                        <ul className="fa-ul">
                                            <li><span className="fa-li"><i className="fas fa-check"/></span><strong>Unlimited
                                                installations</strong></li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Organized terraform that builds VPC, ECS Cluster, DB (production environment on AWS), deployer user, parameters store</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>CLI tool that builds/updates the infrastracture on AWS</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Frontend (Cloudwatch login, Route 53, Cloudfront, S3, IM user roles)</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Deployment tool</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span><strong>Advanced security</strong></li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span><strong>30 hours per year</strong> of DevOps services/consulting, configuration, integration</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>CI/CD - Jenkins</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Basic project functionality</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Testing, linters and other code checking tools, as part of CI/CD</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Basic email integration (SES)</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Background tasks</li>
                                            <li><span className="fa-li"><i className="fas fa-check"/></span>Github integration</li>
                                        </ul>
                                        <a href="/contact" className="btn btn-block btn-primary text-uppercase">Get Started</a>
                                    </div>
                                </div>
                            </section>
                        </div>*/}
                        <div className="row">
                            <section className="col-md-12">
                                <p>The free plan requires a containerized application. Paid plans are billed quarterly, in advance and for a minimum of 12 months.<br/>
                                <strong>A 10% discount will be applied for an upfront annual payment.</strong></p>
                                <h4>Any questions? Don't hesitate to <a href="/contact">drop us a line</a>.</h4>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pricing;
